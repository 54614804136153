import { capitalize } from '@vue/shared'

// typed lower case
export const lowerCase = <T extends string>(str: T) => (str.toLowerCase() as Lowercase<T>)

export { capitalize }

export function parseList<T>(list: string | T[]): T[] {
  if (typeof list === 'string')
    return list.split(',').map(i => i.trim()) as T[]

  return list
}
