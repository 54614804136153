import { notNullish } from './guards'

/**
 * Typed version of Object,keys
 * @param obj - Object to get keys from
 */
export function objectKeys<T extends Record<string, unknown>>(obj: T) {
  return Object.keys(obj) as Array<`${keyof T & (string | number | boolean | null | undefined)}`>
}

/**
 * Typed version of Object.entries
 * @param obj - Object to get entries from
 */
export function objectEntries<T extends Record<string, unknown>>(obj: T) {
  return Object.entries(obj) as Array<[keyof T, T[keyof T]]>
}

/**
 * Create a new subset of an object based on the provided keys.
 * @param obj - Object to get entries from
 * @param keys - Keys to include in the new object
 * @param omitUndefined - If true, any keys which have an undefined value will be omitted from the new object
 */
export function objectPick<O extends Record<string, unknown>, T extends keyof O>(obj: O, keys: T[], omitUndefined = false) {
  return keys.reduce((n, k) => {
    if (k in obj) {
      if (!omitUndefined || obj[k] !== undefined) {
        n[k] = obj[k]
      }
    }
    return n
  }, {} as Pick<O, T>)
}

/**
 * Create a new subset of an object based on the excluded keys.
 * @param obj - Object to get entries from
 * @param keys - Keys to exclude from the new object
 * @param omitUndefined - If true, any keys which have an undefined value will be omitted from the new object
 */
export function objectOmit<O extends Record<string, unknown>, T extends keyof O>(obj: O, keys: T[], omitUndefined = false) {
  return objectPick(obj, objectKeys(obj).filter(k => !keys.includes(k as T)), omitUndefined)
}

/**
 * Map key/value pairs of an object, and construct a new object from the results.
 *
 * Transform:
 * @example
 * ```
 * objectMap({ a: 1, b: 2 }, (k, v) => [k.toString().toUpperCase(), v.toString()])
 * // { A: '1', B: '2' }
 * ```
 *
 * Swap key/value:
 * @example
 * ```
 * objectMap({ a: 1, b: 2 }, (k, v) => [v, k])
 * // { 1: 'a', 2: 'b' }
 * ```
 *
 * Filter keys:
 * @example
 * ```
 * objectMap({ a: 1, b: 2 }, (k, v) => k === 'a' ? undefined : [k, v])
 * // { b: 2 }
 * ```
 */
export function objectMap<K extends string, V, NK = K, NV = V>(obj: Record<K, V>, fn: (key: K, value: V) => [NK, NV] | undefined): Record<K, V> {
  return Object.fromEntries(
    Object.entries(obj)
      .map(([k, v]) => fn(k as K, v as V))
      .filter(notNullish),
  )
}

const defaultCompareFn = (a: string, b: string) => a.localeCompare(b)
export function sortKeys<T extends Record<string, unknown>>(obj: T, compareFn: (a: string, b: string) => number = defaultCompareFn) {
  return Object.fromEntries(Object.entries(obj).sort(([a], [b]) => compareFn(a, b)))
}

/**
 * Clear undefined values from an object, mutates the object
 */
export function clearUndefined<T extends Record<string, unknown>>(obj: T): T {
  Object.keys(obj).forEach((key: string) => (obj[key] === undefined ? delete obj[key] : {}))
  return obj
}

/**
 * Clear nullish values from an object, mutates the object
 */
export function clearNullish<T extends Record<string, unknown>>(obj: T): T {
  Object.keys(obj).forEach((key: string) => (obj[key] == null ? delete obj[key] : {}))
  return obj
}

/**
 * Clear empty values from an object, mutates the object
 */
export function clearEmpty<T extends Record<string, unknown>>(obj: T): T {
  Object.keys(obj).forEach((key: string) => ((obj[key] == null || obj[key] === '') ? delete obj[key] : {}))
  return obj
}

/**
 * Ensures that object has the nested path
 * @param obj - Object to ensure path
 * @param pathKeys - Path to ensure
 * @param value - Value to set at the end of the path
 */
export function objectSet<T extends Record<string, unknown>, K extends keyof T>(obj: T, pathKeys: K[], value: T[K]) {
  const lastIndexKey = pathKeys.length - 1
  for (let i = 0; i < lastIndexKey; ++i) {
    const key = pathKeys[i]
    if (!(key in obj)) {
      obj[key] = {} as T[K]
    }
    obj = obj[key] as T
  }
  obj[pathKeys[lastIndexKey]] = value
}

export const TObject = {
  keys: objectKeys,
  entries: objectEntries,
  pick: objectPick,
  omit: objectOmit,
  map: objectMap,
}
