import { hash } from 'ohash'
import type { StoreState } from '~/composables/store'
import type { OrderListOptions, SearchQuery, UzoProductSearchResultFragment, UzoSearchInput } from '#graphql-operations'

export function getSearchResult(state: StoreState, searchHash: string, input: UzoSearchInput & { page: number }): SearchQuery | undefined {
  const ids: string[] | undefined = state.search?.[searchHash]?.pages?.[input.page]
  const search = state.search?.[searchHash]?.search
  const searchFacetValues = state.search?.[searchHash]?.searchFacetValues
  if (isArray(ids) && search && searchFacetValues) {
    // checking for ids to be an array is necessary because the ids array can be empty, when the search result is empty
    const items = ids.map(id => state.items?.[id]).filter(Boolean) as UzoProductSearchResultFragment[]

    return {
      search: { ...search, items },
      searchFacetValues,
    }
  }

  return undefined
}

export function fetchSearch(input: UzoSearchInput & { page: number; facetValueIds: string[] }) {
  const state = useStore()
  const searchHash = createStoreStateHash(input)

  return reactiveLoad<SearchQuery>(
    () => getSearchResult(state.value, searchHash, input),
    (result) => {
      state.value.search ??= {}
      state.value.search[searchHash] ??= {}
      state.value.search[searchHash]!.pages ??= {}

      const { search: { totalItems, facetValues }, searchFacetValues } = result
      state.value.search[searchHash]!.search = { totalItems, facetValues }
      state.value.search[searchHash]!.searchFacetValues = searchFacetValues

      state.value.search[searchHash]!.pages![input.page] = result.search.items.map(item => item.id)

      result.search.items.filter(Boolean).forEach((item) => {
        const productItem = state.value.items?.[item.id]
        if (productItem) {
          Object.assign(productItem, item)
        } else {
          state.value.items ??= {}
          state.value.items[item.id] = item
        }
      })

      result.searchFacetValues?.facetValues?.filter(Boolean).forEach(({ facetValue }) => {
        const fv = state.value.facetValue?.[facetValue?.id]
        if (fv) {
          Object.assign(fv, facetValue)
        } else {
          state.value.facetValue ??= {}
          state.value.facetValue[facetValue?.id] = facetValue
        }
      })
    },
    () => useGraphqlQuery('productSearch', {
      search: {
        term: input.term,
        collectionId: input.collectionId,
        facetValueFilters: input.facetValueIds?.length ? [{ or: input.facetValueIds }] : [],
        skip: getPaginationOffset(input.page, +input.take!),
        take: input.take,
      },
      searchFacetValues: {
        term: input.term,
        collectionId: input.collectionId,
        facetValueFilters: [], // keep empty to get all for current search, for filtering
      },
    }).then(result => result.data),
    {
      search: {
        ...state.value.search?.[searchHash]?.search,
        items: (state.value.search?.[searchHash]?.pages?.[input.page] || []).slice(input.skip!, input.skip! + input.take!).map(id => state.value.items?.[id]).filter(Boolean),
      },
      searchFacetValues: state.value.search?.[searchHash]?.searchFacetValues,
    } as SearchQuery,
  )
}

/**
 * Sorts the keys of an object and array values and returns a hash
 * @param input - The UzoSearchInput object
 */
export function createStoreStateHash(input: (UzoSearchInput | OrderListOptions) & { page: number; facetValueIds: string[] }) {
  const isOrderListOptions = !isSearchInput(input)

  if (!isOrderListOptions) {
    if (input.facetValueIds && input.facetValueIds.length)
      input.facetValueIds = parseList(input.facetValueIds).sort()

    if (input.facetValueFilters && input.facetValueFilters.length)
      input.facetValueFilters = parseList(input.facetValueFilters).sort()
  }

  // omitting the take and skip params from the hash so we can cache the results
  // also omitting collectionId and collectionSlug as they identify the collection
  const obj = TObject.omit(input, ['page', 'take', 'skip', 'collectionId', 'collectionSlug'] as any[])

  const baseHash = hash(sortKeys(obj))

  if (isOrderListOptions)
    return `orders.${baseHash}#${input.take}`

  return `${input.collectionId ? `${input.collectionId}/` : ''}${baseHash}#${input.take}`
}

function isSearchInput(input: any): input is UzoSearchInput {
  return input.term !== undefined
}
