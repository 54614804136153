import { hasOwn, isDate, isFunction, isMap, isObject, isPlainObject, isPromise, isSet, isString, isSymbol, toNumber } from '@vue/shared'
import { isClient, isDef } from '@vueuse/shared'

const isBoolean = (value: unknown): value is boolean => typeof value === 'boolean'
const isNumber = (value: unknown): value is number => typeof value === 'number'

const isArray = <T = unknown>(value: unknown): value is T[] => Array.isArray(value)

function toInteger(value: unknown) {
  const num = toNumber(value)
  return Number.isInteger(num) ? num : value
}

const notNullish = <T>(v: T | null | undefined): v is NonNullable<T> => v != null

export {
  isClient,
  isString,
  isArray,
  isObject,
  isPlainObject,
  isFunction,
  isNumber,
  isSymbol,
  isBoolean,
  isDate,
  isPromise,
  isSet,
  isMap,
  isDef,
  hasOwn,
  toNumber,
  toInteger,
  notNullish,
}
